<template>
  <CriaCredito />
</template>

<script>
import CriaCredito from "@/components/colaboracoes/CriaColaboracao.vue";

export default {
  components: {
    CriaCredito
  }
};
</script>
